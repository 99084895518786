import React from 'react'
import { Container, Typography } from '@mui/material'

const AboutUs = () => {
  return (
    <Container>
      <Typography variant='h2'>About Us</Typography>
    </Container>
  )
}

export default AboutUs