import React from 'react'
import { Typography } from '@mui/material'

const Products = () => {
  return (
    <>
      <Typography variant='h2'>Products</Typography>
    </>
  )
}

export default Products