import React from 'react'
import { Container, Typography } from '@mui/material'

const ContactUs = () => {
  return (
    <Container>
      <Typography variant='h2'>Contact Us</Typography>
    </Container>
  )
}

export default ContactUs